import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//Components
import Main from '../components/Contacto/main'
import BannerTop from "../components/Modules/MediaPush/BannerTop"

const IndexPage = () => (
  <Layout hideFooter={true}>
    <Seo title="Contacto" description="Aquí debería ir la descripción de la sección de contacto" />
    <BannerTop page="contacto"/>
    <Main />
  </Layout>
)

export default IndexPage
