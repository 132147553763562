import React from 'react';
import Formulario from '../Contact';
import Breadcrumbs from '../breadcrumbs';
import { graphql, useStaticQuery } from 'gatsby';

const Main = () => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
              global_email
              global_phone
              global_whatsapp
              keys {
                captcha
              }
              social{
                facebook
                instagram
                twitter
                youtube
                linkedin
              }
              branch_office{
                name
                address
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              sections {
                    services{
                        title
                        subtitle
                        paragraph
                    }
                    footer {
                        title_contact
                        title_social
                        title_newsletter
                        title_office
                        button_contact{
                            link
                            value
                        }
                    }
              }
          }
    }`)

    return(
        <section className="contact-main">
            <div className="container-fluid">
                <div className="row height">
                    <section class="contact-main">
                        <div class="col-12">
                            <div class="row center align-items-center">
                                <div className="col-12">
                                    <Breadcrumbs
                                        props={[
                                        { name: "Home", route: "/", location: "" },
                                        { name: "Contacto", route:   "", location: ""},
                                        ]}
                                    />
                                </div>
                                <div class="col-12">
                                    <div class="row content-main d-flex align-items-start ">
                                        <div class="col-12 mt-auto mb-lg-5">
                                            <h2>
                                                ¿Tenés dudas? <br class="d-block d-lg-none"/> Contactanos. <span>=)</span>
                                            </h2>
                                        </div>
                                        <div class="col-lg-6 d-flex flex-colum align-self-stretch"> 
                                            <div class="content-contact row">
                                                <div class="col-12">
                                                    {/* {realEstate.branch_office.map(item => (
                                                        <>
                                                            <div class="px-0 mb-1 d-flex">
                                                                <p>Whatsapp <a target="_blank" 
                                                                    href={`https://api.whatsapp.com/send?text=%C2%A1Hola!&phone=${item.contact.whatsapp}`}>
                                                                        {item.contact.whatsapp}</a></p>
                                                            </div>
                                                            {item.contact.phone.map(phone => (
                                                                <div class="px-0 d-flex mb-1">
                                                                    <p>Tel. {phone} </p>
                                                                </div>
                                                            ))}
                                                        </>
                                                    ))} */}
                                                    <div class="px-0 d-flex mb-1">
                                                        <a target="_blank" class="under-line d-none d-lg-table" 
                                                            href={"https://web.whatsapp.com/send?text=%C2%A1Hola!" + "&phone=+" + realEstate.global_whatsapp.replaceAll("-",'')} >Whatsapp. {realEstate?.global_whatsapp}</a>
                                                        <a target="_blank" class="under-line d-table d-lg-none" 
                                                            href={"https://api.whatsapp.com/send?text=%C2%A1Hola!" + "&phone=+" + realEstate.global_whatsapp.replaceAll("-",'')}>Whatsapp. {realEstate?.global_whatsapp}</a>
                                                    </div>
                                                    {(realEstate?.global_phone)?.map(phone => (
                                                        <div class="px-0 d-flex mb-1">
                                                            <p>Tel. {phone}</p>
                                                        </div>
                                                    ))}
                                                    <p><a class="mailto" href={`mailto:${realEstate?.global_email}`}>{realEstate?.global_email}</a></p>
                                                    <div class="social-content mt-5 mb-1 d-flex align-items-center">
                                                        <a href={realEstate.social.facebook} target="_blank"><i class="d-none d-lg-inline icon-facebook"></i></a>
                                                        <a href={realEstate.social.instagram} target="_blank"><i class="d-none d-lg-inline icon-instagram"></i></a>
                                                        <a href={realEstate.social.youtube} target="_blank"><i class="d-none d-lg-inline icon-youtube"></i></a>
                                                        <a href={`https://web.whatsapp.com/send?text=%C2%A1Hola!&phone=${realEstate.branch_office[0].contact.whatsapp}`}target="_blank"><i class="d-none d-lg-inline icon-whatsapp"></i></a>
                                                    </div>
                                                </div>
                                                <div class="col-12 d-lg-flex">
                                                    {realEstate.branch_office.length > 0 &&
                                                        realEstate.branch_office.map((branch,index) => (
                                                            <div className="single mb-3 mb-lg-0">
                                                                <h5  className="col-lg-12 col-4">{branch.name}</h5>
                                                                <div className="col-lg-12 col-8">
                                                                    <p className="mb-0 d-block">{branch.address}, {branch.city}</p>
                                                                    <p className="mb-0 d-block">{branch.region}</p>
                                                                    {branch.contact.phone.map(phone => (
                                                                        <p className="mb-0 d-block">Teléfono {phone}</p>
                                                                    ))}
                                                                    <p className="mb-0 d-block">{branch.contact.mail}</p>
                                                                    <p className={branch.contact.whatsapp === '' ? 'd-none' : 'mb-0 d-block'}>Whatsapp +{ branch.contact.whatsapp }</p>
                                                                    <p className={branch.broker_id === '' ? 'd-none' : 'mb-0 d-block'}>Matrícula {branch.broker_id}</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 mt-lg-0 mt-4">
                                            <Formulario from={'contact'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    )
} 

export default Main;